export const CAMPAIGN = {
  Banner: 1,
  Interstitial: 2,
  Native: 3,
  RewardVideo: 4,
  FrontVideo: 5,
  InterstitialVideo: 6,
  Splash: 7,
  BottomModal: 8,
  // S2SInstreamVideo: 9, // 네이버 S2S 인스트림 비디오 (치지직)
  // InstreamVideo: 10, // 인스트림 비디오
  S2SRV: 11, // 네이버 S2S 리워드 비디오
  WebBanner: 100,
  OneStore: 51,
  RewardCPM: 52,
  RewardCPC: 53, // legacy
} as const;

export type Campaign = Union<typeof CAMPAIGN>;

export const CAMPAIGN_ALIAS = {
  [CAMPAIGN.Banner]: "배너",
  [CAMPAIGN.Interstitial]: "전면",
  [CAMPAIGN.Native]: "네이티브",
  [CAMPAIGN.RewardVideo]: "리워드 비디오",
  [CAMPAIGN.InterstitialVideo]: "전면 비디오",
  [CAMPAIGN.FrontVideo]: "전면 + 비디오", // 추후 제거 예정
  [CAMPAIGN.Splash]: "Splash",
  [CAMPAIGN.BottomModal]: "모달 광고",
  // [CAMPAIGN.S2SInstreamVideo]: "S2S 인스트림 비디오",
  // [CAMPAIGN.InstreamVideo]: "인스트림 비디오",
  [CAMPAIGN.S2SRV]: "S2S 리워드 비디오",
  [CAMPAIGN.WebBanner]: "웹 배너",
  [CAMPAIGN.OneStore]: "원스토어 커스텀",
  [CAMPAIGN.RewardCPM]: "rCPM",
  [CAMPAIGN.RewardCPC]: "rCPC (legacy)",
};

/**
 * 컴패니언 광고 : 1, 5 (Default : 1)
 * 배너-네이티브 320x50 : 6(Default : 6)
 * 배너-네이티브 320x100 : 7, 8, 9(Default : 7)
 * 배너-네이티브 300x250 : 10, 11, 21, 22 (Default : 10)
 * 전면-네이티브 : 12, 13 (Default : 12)
 * 네이티브 : 14~20 (Default : 0)
 */
export const TEMPLATE = {
  COMPANION_A: 1,
  COMPANION_B: 5,
  BANNER_SMALL_A: 6,
  BANNER_MEDIUM_A: 7,
  BANNER_MEDIUM_B: 8,
  BANNER_MEDIUM_C: 9,
  BANNER_LARGE_A: 10,
  BANNER_LARGE_B: 11,
  INTERSTITIAL_A: 12,
  INTERSTITIAL_B: 13,
  NATIVE_NO_TEMPLATE: 0,
  NATIVE_COMMON_A: 14,
  NATIVE_COMMON_B: 15,
  NATIVE_ADFIT: 16,
  NATIVE_NAM_XSMALL: 17,
  NATIVE_NAM_SMALL: 18,
  NATIVE_NAM_MEDIUM: 19,
  NATIVE_NAM_LARGE: 20,
  NATIVE_NAM_IMG_BANNER_A: 21,
  NATIVE_NAM_IMG_BANNER_B: 22,
  NATIVE_NAM_IMG_BANNER_C: 23,
  NATIVE_NAM_IMG_BANNER_D: 24,
} as const;

export type Template = Union<typeof TEMPLATE>;

export const TEMPLATE_ALIAS = {
  [TEMPLATE.COMPANION_A]: "A Type",
  [TEMPLATE.COMPANION_B]: "B Type",
  [TEMPLATE.BANNER_SMALL_A]: "A Type",
  [TEMPLATE.BANNER_MEDIUM_A]: "Board Type A",
  [TEMPLATE.BANNER_MEDIUM_B]: "Board Type B",
  [TEMPLATE.BANNER_MEDIUM_C]: "Board Type C",
  [TEMPLATE.BANNER_LARGE_A]: "Feed Type A",
  [TEMPLATE.BANNER_LARGE_B]: "Feed Type B",
  [TEMPLATE.INTERSTITIAL_A]: "Instant Type A",
  [TEMPLATE.INTERSTITIAL_B]: "Instant Type B",
  [TEMPLATE.NATIVE_NO_TEMPLATE]: "템플릿 사용안함",
  [TEMPLATE.NATIVE_COMMON_A]: "Common 320x280 A",
  [TEMPLATE.NATIVE_COMMON_B]: "Common 320x280 B",
  [TEMPLATE.NATIVE_ADFIT]: "Adfit 320x80",
  [TEMPLATE.NATIVE_NAM_XSMALL]: "NAM 360x50",
  [TEMPLATE.NATIVE_NAM_SMALL]: "NAM 360x80",
  [TEMPLATE.NATIVE_NAM_MEDIUM]: "NAM 360x100",
  [TEMPLATE.NATIVE_NAM_LARGE]: "NAM 360x150",
  [TEMPLATE.NATIVE_NAM_IMG_BANNER_A]: "NAM Image Banner Type A",
  [TEMPLATE.NATIVE_NAM_IMG_BANNER_B]: "NAM Image Banner Type B",
  [TEMPLATE.NATIVE_NAM_IMG_BANNER_C]: "NAM Image Banner Type C",
  [TEMPLATE.NATIVE_NAM_IMG_BANNER_D]: "NAM Image Banner Type D",
};
