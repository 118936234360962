import { Box, Button, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parse, stringify } from "qs";
import { array, mixed, object, string } from "yup";

import useNpayReport, { NpayReport, NpayReportParams } from "src/hooks/apis/npay/use-npay-report";
import useNpayAppList, { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import { STATUS } from "src/types";
import useToast from "src/hooks/useToast";
import { BasicTable, DateField, MultipleSelect, Select } from "src/components/commons";
import { shouldErrorShows } from "src/utils/form-helper";
import DataFormat from "src/assets/formats";

import { MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR } from "../const";
import ReportChart from "./ReportChart";

const searchParamsValidationSchema = object({
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until),
  media_keys: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
});

const defaultSearchParams: NpayReportParams = {
  since: moment().subtract(7, "d").format("YYYYMMDD"),
  until: moment().subtract(1, "d").format("YYYYMMDD"),
  media_keys: [],
};

type FormValues = Pick<NpayReportParams, "media_keys"> & {
  since: Moment | null;
  until: Moment | null;
  field: keyof Pick<
    NpayReport,
    "points" | "canceled_points" | "clicks" | "complete_views" | "revenue"
  >;
};

const formValidationSchema = object({
  since: mixed().required(),
  until: mixed().required(),
  media_keys: array().of(string()),
  field: string().required(),
});

const REPORT_OPTIONS: { label: string; value: FormValues["field"] }[] = [
  {
    label: "일간 포인트 지급량",
    value: "points",
  },
  {
    label: "일간 포인트 취소량",
    value: "canceled_points",
  },
  {
    label: "포인트 지급 플레이스먼트 클릭 수",
    value: "clicks",
  },
  {
    label: "포인트 지급 플레이스먼트 RV 완료 수",
    value: "complete_views",
  },
  {
    label: "포인트 지급 플레이스먼트 매출액",
    value: "revenue",
  },
];

const columnDef: ColDef[] = [
  {
    field: "report_date",
    headerName: "일자",
    valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
  },
  // {
  //   field: "media_name",
  //   headerName: "앱 매체 명",
  // },
  {
    field: "points",
    headerName: "일간 포인트 지급량",
    valueFormatter: (params) => DataFormat.npay_points.formatter(params.value),
  },
  {
    field: "canceled_points",
    headerName: "일간 포인트 취소량",
    valueFormatter: (params) => DataFormat.npay_canceled_points.formatter(params.value),
  },
  {
    field: "clicks",
    headerName: "포인트 지급 플레이스먼트 클릭 수",
    valueFormatter: (params) => DataFormat.npay_clicks.formatter(params.value),
  },
  {
    field: "complete_views",
    headerName: "포인트 지급 플레이스먼트 RV 완료 수",
    valueFormatter: (params) => DataFormat.npay_complete_views.formatter(params.value),
  },
  {
    field: "revenue",
    headerName: "포인트 지급 플레이스먼트 매출액",
    valueFormatter: (params) => DataFormat.npay_revenue.formatter(params.value),
  },
  {
    field: "total_revenue",
    headerName: "전체 매출액",
    valueFormatter: (params) => DataFormat.npay_revenue.formatter(params.value),
  },
  {
    field: "total_clicks",
    headerName: "전체 클릭 수",
    valueFormatter: (params) => DataFormat.npay_clicks.formatter(params.value),
  },
];

export default function Report() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi>();

  const [params, setParams] = useState(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        since: validatedParams.since
          ? moment(validatedParams.since).format("YYYYMMDD")
          : defaultSearchParams.since,
        until: validatedParams.until
          ? moment(validatedParams.until).format("YYYYMMDD")
          : defaultSearchParams.until,
        media_keys: Array.isArray(validatedParams.media_keys)
          ? validatedParams.media_keys
          : typeof validatedParams.media_keys === "string"
          ? validatedParams.media_keys.split(",")
          : defaultSearchParams.media_keys,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [enableQuery, setEnableQuery] = useState(true);

  const initialFormValues = useMemo<FormValues>(() => {
    const { media_keys, since, until } = params;

    return {
      media_keys,
      since: moment(since).startOf("d"),
      until: moment(until).startOf("d"),
      field: "points",
    };
  }, [params]);

  const handleSubmitForm = ({ field, ...rest }: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...rest,
      since: rest.since?.format("YYYYMMDD") || "",
      until: rest.until?.format("YYYYMMDD") || "",
    }));

    setEnableQuery(true);
  };

  // formik
  const {
    setValues,
    setFieldValue,
    values: formValues,
    isValid,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: formValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const query = useNpayReport(params, {
    enabled: enableQuery,
    onSuccess: () => {
      setEnableQuery(false);
    },
  });

  // 앱 매체 목록 query (select 용도)
  const npayAppListQuery = useNpayAppList(
    {
      page_no: 1,
      page_size: 10000,
      status: STATUS.ACTIVE,
    },
    {
      onSuccess: (response) => {
        const { rows } = response.data.data;
        const allMediaKeys = rows.map((app) => app.key);

        setParams((prev) => ({
          ...prev,
          media_keys:
            (prev.media_keys || []).length <= 0
              ? allMediaKeys
              : allMediaKeys.filter((key) => prev.media_keys?.includes(key)),
        }));
      },
    }
  );

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/npay/report" + searchParams, {
      replace: true,
    });
  }, [navigate, params]);

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            ...prev,
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            ...prev,
            since: value,
            until: newEndDate.isAfter(MAX_DATE) ? MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleChangeApp = useCallback(
    (values: NpayApp[]) => {
      setFieldValue(
        "media_keys",
        values.map((app) => app.key)
      );
    },
    [setFieldValue]
  );

  const handleChangeField = useCallback(
    (e: SelectChangeEvent<FormValues["field"]>) => {
      const { value } = e.target;

      setFieldValue("field", value);
    },
    [setFieldValue]
  );

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  const handleExportExcel = useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        columnGroups: true,
        fileName: `네이버페이관리_리포트(${params.since}-${params.until}).xlsx`,
      });
    }
  }, [gridApi, params]);

  const selectedApps = npayAppListQuery.data.rows.filter((app) =>
    (formValues.media_keys || []).includes(app.key)
  );

  const aggData = useMemo(() => {
    const daysDiff = moment(params.until).diff(moment(params.since), "d") + 1;

    return Array.from({ length: daysDiff }, (_, idx) => {
      const date = moment()
        .subtract(idx + 1, "d")
        .format("YYYYMMDD");

      const defaultItem = {
        report_date: date,
        points: 0, // 일간 포인트 지급량
        canceled_points: 0, // 일간 포인트 취소량
        clicks: 0, // 포인트 지급 플레이스먼트 클릭 수
        complete_views: 0, // 포인트 지급 플레이스먼트 RV 완료 수
        revenue: 0, // 포인트 지급 플레이스먼트 매출액
        total_clicks: 0,
        total_partner_revenue: 0,
        total_revenue: 0,
      };

      const sum = query.data
        .filter((item) => item.report_date === date)
        .reduce((prev, cur) => {
          prev.points += cur.points;
          prev.canceled_points += cur.canceled_points;
          prev.clicks += cur.clicks;
          prev.complete_views += cur.complete_views;
          prev.revenue += cur.revenue;
          prev.total_clicks += cur.total_clicks;
          prev.total_partner_revenue += cur.total_partner_revenue;
          prev.total_revenue += cur.total_revenue;

          return prev;
        }, defaultItem);

      return sum;
    }).reverse();
  }, [params.since, params.until, query.data]);

  const tableData = useMemo(() => {
    return aggData.map((item) => ({ ...item, media_name: "선택된 매체" })).reverse();
  }, [aggData]);

  return (
    <Stack mt="2rem" mb="1rem" spacing={2}>
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* 시작일 */}
          <Box sx={{ width: 200 }}>
            <DateField
              label="시작일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={formValues.since}
              onChange={handleChangeDates("since")}
              InputProps={{
                onBlur: handleBlur("since"),
                error: shouldErrorShows("since", touched, errors),
              }}
              disabled={query.isFetching}
            />
          </Box>

          {/* 종료일 */}
          <Box sx={{ width: 200 }}>
            <DateField
              label="종료일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={formValues.until}
              onChange={handleChangeDates("until")}
              InputProps={{
                onBlur: handleBlur("until"),
                error: shouldErrorShows("until", touched, errors),
              }}
              disabled={query.isFetching}
            />
          </Box>

          {/* 앱 매체 명 */}
          <Box sx={{ width: 300 }}>
            <MultipleSelect
              options={npayAppListQuery.data.rows}
              label="앱 매체 명"
              placeholder="앱 매체 선택"
              getOptionLabel={(app) => app.name}
              getOptionValue={(app) => app.key}
              onChange={handleChangeApp}
              value={selectedApps}
              disabled={query.isFetching || npayAppListQuery.isFetching}
            />
          </Box>

          {/* 리포트 항목 */}
          <Box sx={{ width: 300 }}>
            <Select
              label="상태"
              onChange={handleChangeField}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={formValues.field}
              disabled={query.isFetching}
            >
              {REPORT_OPTIONS.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* 조회 버튼 */}
          <Button type="submit" variant="contained" disabled={!isValid || query.isFetching}>
            검색
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ height: 500 }}>
        <ReportChart data={aggData} dataKey={formValues.field} />
      </Box>

      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <Button variant="outlined" onClick={handleExportExcel} disabled={query.isFetching}>
            엑셀 다운로드
          </Button>
        </Stack>
        <BasicTable
          getRowId={(params) => params.data.report_date}
          animateRows
          rowData={tableData}
          columnDefs={columnDef}
          onGridReady={handleGridReady}
        />
      </Stack>
    </Stack>
  );
}
