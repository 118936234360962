import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";

import useGetAdFormatInfo, { AdFormat } from "src/hooks/apis/info/useGetAdFormatInfo";
import useNativeTemplateList, {
  NativeTemplateType,
} from "src/hooks/apis/placements/useNativeTemplateList";

import { Select } from "src/components/commons";
import { Campaign, CAMPAIGN, TEMPLATE, Template, TEMPLATE_ALIAS } from "src/types";

import { CampaignInfo } from "./AddPlacementModal";
import { addCampaignModalStyle } from "./styles";
import {
  campaignTypeList,
  campaignValidationSchema,
  getPreviewImgSize,
  isNativeCampaign,
  isComponentRequired,
  isMinViewTimeRequired,
  isMuteRequired,
  isSizeRequired,
  previewImgByCampaign,
} from "./const";
import { useRecoilValue } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";

interface AddCampaignModalProps {
  onClose: () => void;
  open: boolean;
  onSubmit: (values: CampaignInfo) => void;
}

const defaultPreviewImgSize = { width: 180, height: 385 };
const NAVER_COMPANY_KEY = process.env.REACT_APP_NAVER_COMPANY_KEY;

const AddCampaignModal = ({ onClose, open, onSubmit }: AddCampaignModalProps) => {
  const { key: companyKey } = useRecoilValue(viewerCompany);

  /** 네이티브 템플릿 옵션 목록 */
  const [templateOptions, setTemplateOptions] = useState([
    {
      templateNo: TEMPLATE.NATIVE_NO_TEMPLATE as Template,
      name: TEMPLATE_ALIAS[TEMPLATE.NATIVE_NO_TEMPLATE],
    },
  ]);

  /** 미리보기 이미지 속성 */
  const [previewImgProps, setPreviewImgProps] = useState({
    ...defaultPreviewImgSize,
    src: previewImgByCampaign[CAMPAIGN.Banner](),
  });

  const { data: adFormatData } = useGetAdFormatInfo({
    types: [
      CAMPAIGN.Banner,
      CAMPAIGN.Interstitial,
      CAMPAIGN.Native,
      CAMPAIGN.RewardVideo,
      CAMPAIGN.InterstitialVideo,
    ],
  });

  const initialValues: CampaignInfo = useMemo(
    () => ({
      type: CAMPAIGN.Banner,
      size: adFormatData.formats.find((format) => format.type === CAMPAIGN.Banner)?.id || 0,
      minViewTime: 1,
      iconImageRequired: false,
      titleRequired: false,
      descRequired: false,
      mainImageRequired: false,
      ctatextRequired: false,
      mute: false,
      // NOTE: type === CAMPAIGN.Native인 경우에만 사용
      templateNo: TEMPLATE.NATIVE_NO_TEMPLATE,
    }),
    [adFormatData.formats]
  );

  const { getFieldProps, setFieldValue, setValues, handleSubmit, values } = useFormik({
    initialValues,
    validationSchema: campaignValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  const { nativeTemplateList, isLoading: isNativeTemplateListLoading } = useNativeTemplateList(
    NativeTemplateType.NATIVE,
    {
      enabled: values.type === CAMPAIGN.Native,
      onSuccess: (response) => {
        const { data: nativeTemplateList } = response;
        const templateNo = values.templateNo;
        const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

        if (!selectedNativeTemplate) {
          throw new Error("존재하지 않는 템플릿입니다.");
        }

        // 템플릿 옵션 목록 업데이트
        setTemplateOptions(
          nativeTemplateList.map(({ id, name }) => ({
            templateNo: id,
            name,
          }))
        );

        // 미리보기 이미지 업데이트
        setPreviewImgProps({
          ...getPreviewImgSize(selectedNativeTemplate.id),
          src:
            selectedNativeTemplate.id === TEMPLATE.NATIVE_NO_TEMPLATE
              ? previewImgByCampaign[CAMPAIGN.Native]
              : selectedNativeTemplate.iurl,
        });
      },
    }
  );

  const onChangeCampaignType = useCallback(
    (e: SelectChangeEvent<Campaign>) => {
      const type = +e.target.value as Campaign;

      const newValues: Partial<CampaignInfo> = {
        type,
        size: adFormatData.formats.find((format) => format.type === type)?.id || 0,
        templateNo: TEMPLATE.NATIVE_NO_TEMPLATE,
        // 캠페인 타입 변경 시 "컴포넌트" 영역 값 초기화
        iconImageRequired: false,
        titleRequired: false,
        descRequired: false,
        mainImageRequired: false,
        ctatextRequired: false,
      };

      if (type === CAMPAIGN.Interstitial) {
        newValues.minViewTime = 1;
      }

      if (type === CAMPAIGN.InterstitialVideo) {
        newValues.minViewTime = 5;
      }

      setValues((prev) => ({ ...prev, ...newValues }));

      setPreviewImgProps(() => {
        const previewImg = previewImgByCampaign[type];

        return {
          ...(([CAMPAIGN.Splash, CAMPAIGN.BottomModal] as Campaign[]).includes(type)
            ? { width: 320, height: 385 }
            : defaultPreviewImgSize),
          src: typeof previewImg === "function" ? previewImg() : previewImg,
        };
      });
    },
    [adFormatData.formats, setValues]
  );

  const onChangeCampaignSize = useCallback(
    (e: SelectChangeEvent<AdFormat["id"]>) => {
      const size = +e.target.value;
      setFieldValue("size", size);

      setPreviewImgProps((prev) => {
        if (values.type === CAMPAIGN.Banner) {
          return { ...defaultPreviewImgSize, src: previewImgByCampaign[CAMPAIGN.Banner](size) };
        }

        return prev;
      });
    },
    [setFieldValue, values.type]
  );

  const onChangeMinHoldingTime = useCallback(
    (e: SelectChangeEvent<number>) => {
      setFieldValue("minViewTime", +e.target.value);
    },
    [setFieldValue]
  );

  /** 네이티브 광고 템플릿 옵션 변경 핸들러 */
  const onChangeTemplateNo = useCallback(
    (e: SelectChangeEvent<Template>) => {
      const templateNo = +e.target.value as Template;
      const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

      if (!selectedNativeTemplate) {
        throw new Error("존재하지 않는 템플릿입니다.");
      }

      setFieldValue("templateNo", selectedNativeTemplate.id);
      setValues((prev) => ({
        ...prev,
        templateNo: selectedNativeTemplate.id,
        // 광고형식: 네이티브에 "Default" 템플릿이 아닌 경우 컴포넌트 영역의 값을 초기화
        ...(selectedNativeTemplate.id !== TEMPLATE.NATIVE_NO_TEMPLATE && {
          iconImageRequired: false,
          titleRequired: false,
          descRequired: false,
          mainImageRequired: false,
          ctatextRequired: false,
        }),
      }));

      setPreviewImgProps({
        ...getPreviewImgSize(selectedNativeTemplate.id),
        src:
          selectedNativeTemplate.id === TEMPLATE.NATIVE_NO_TEMPLATE
            ? previewImgByCampaign[CAMPAIGN.Native]
            : selectedNativeTemplate.iurl,
      });
    },
    [nativeTemplateList, setFieldValue, setValues]
  );

  const campaignOptions = useMemo(() => {
    const isNaver = companyKey === NAVER_COMPANY_KEY;

    if (isNaver) {
      return campaignTypeList;
    }

    const naverCampaigns = [
      // CAMPAIGN.S2SInstreamVideo,
      CAMPAIGN.S2SRV,
    ] as Campaign[];

    return campaignTypeList.filter((option) => !naverCampaigns.includes(option.type));
  }, [companyKey]);

  return (
    <Dialog
      css={addCampaignModalStyle}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="representative-campaign"
      aria-describedby="add campaign"
    >
      <DialogTitle id="dialog-title">광고 형식 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container>
          {/* 미리보기 영역 */}
          <Grid item xs={6} className="preview" sx={{ minHeight: defaultPreviewImgSize.height }}>
            {isNativeTemplateListLoading ? (
              <CircularProgress />
            ) : (
              <img {...previewImgProps} alt="preview" />
            )}
          </Grid>
          <Grid item xs={6}>
            <form id="add-campaign-form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* 캠페인 타입 */}
                <Grid item xs={12}>
                  <Select
                    label="광고형식"
                    {...getFieldProps("type")}
                    onChange={onChangeCampaignType}
                  >
                    {campaignOptions.map((option) => (
                      <MenuItem key={option.type} value={option.type}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* 사이즈 */}
                {isSizeRequired(values.type) && (
                  <Grid item xs={12}>
                    <Select
                      label="사이즈"
                      placeholder="사이즈를 선택해주세요."
                      {...getFieldProps("size")}
                      onChange={onChangeCampaignSize}
                    >
                      {adFormatData.formats
                        .filter((format) => format.type === values.type)
                        .map((format) => (
                          <MenuItem key={format.id} value={format.id}>
                            {`[${format.id}] ${format.label}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                )}

                {/* 네이티브 광고 템플릿 */}
                {isNativeCampaign(values.type) && (
                  <Grid item xs={12}>
                    <Select
                      label="네이티브 광고 템플릿"
                      disabled={isNativeTemplateListLoading}
                      {...getFieldProps("templateNo")}
                      onChange={onChangeTemplateNo}
                    >
                      {templateOptions.map((option) => (
                        <MenuItem key={option.templateNo} value={option.templateNo}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}

                {/* 최소 시청 시간 */}
                {isMinViewTimeRequired(values.type) && (
                  <Grid item xs={12}>
                    <Select
                      label="최소 광고 유지 시간"
                      {...getFieldProps("minViewTime")}
                      onChange={onChangeMinHoldingTime}
                    >
                      {[1, 3, 5, 15].map((minHoldingTime) => (
                        <MenuItem key={minHoldingTime} value={minHoldingTime}>
                          {`${minHoldingTime} 초`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}

                {/* 네이티브 캠페인 컴포넌트 구성 */}
                {isComponentRequired(values.type, values.templateNo as Template) && (
                  <Grid item xs={12} className="component">
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">컴포넌트</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("iconImageRequired")} />}
                          label="APP ICON"
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("titleRequired")} />}
                          label="TITLE"
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("descRequired")} />}
                          label="TEXT"
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("mainImageRequired")} />}
                          label="IMAGE"
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("ctatextRequired")} />}
                          label="CTA TITLE"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}

                {/* 음소거 설정 */}
                {isMuteRequired(values.type) && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="switch"
                      control={<Switch color="primary" />}
                      label="비디오 음소거"
                      labelPlacement="start"
                      {...getFieldProps("mute")}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-campaign-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCampaignModal;
